@tailwind base;
@tailwind components;
@tailwind utilities;
.html {
  max-width: 100vw;
}
@font-face {
    font-family: 'Gilroy-Bold';
    src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy-Medium';
    src: local('Gilroy-Medium'), url(./fonts/Gilroy-Medium.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy-Light';
    src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Gilroy-Heavy';
    src: local('Gilroy-Heavy'), url(./fonts/Gilroy-Heavy.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Gilroy-Regular';
    src: local('Gilroy-Regular'), url(./fonts/Gilroy-Regular.ttf) format('truetype');
  }